import __ from 'lodash';
import * as DateFn from 'date-fns';
import enGB from 'date-fns/locale/en-GB';

export function formatToPlainNumber(n: number | string) {
  return n && String(n) ? String(n).replace(/[^0-9.]/g, '') : '';
}

const wrapNegative = function (s: string, scaled: string | number, p: string) {
  if (+scaled < 0) {
    // negative
    const formatted = parseFloat(
      String(Math.round(+scaled * -100) / 100)
    ).toFixed(2);
    return `(${s}${formatted}${p})`;
  }
  // positive
  const formatted = parseFloat(String(Math.round(+scaled * 100) / 100)).toFixed(
    2
  );
  return `${s}${formatted}${p}`;
};

export function abbreviateCurrency(number: string, symbol = '$') {
  const SI_POSTFIXES = ['', 'k', 'M', 'G', 'T', 'P', 'E'];

  if (!+number) {
    return `${symbol}0.00`;
  }

  // what tier? (determines SI prefix)
  // eslint-disable-next-line no-bitwise
  const tier = (Math.log10(Math.abs(+number)) / 3) | 0;

  // get postfix and determine scale
  const postfix = SI_POSTFIXES[tier];
  const scale = 10 ** (tier * 3);

  // scale the number
  const scaled = +number / scale;

  // format number and add postfix as suffix
  return wrapNegative(symbol, scaled, postfix);
}

export const validateMaxNumber = (maxNumber: number) => (value: any) => {
  const num = Number(value);
  if (num < 0) {
    return 0;
  }
  return Math.min(num, maxNumber);
};

export function convertObjectKeysToCamelCase(obj: any) {
  if (typeof obj !== 'object' || !obj || Array.isArray(obj)) {
    return obj;
  }
  const newObj: any = {};
  Object.keys(obj).forEach(key => {
    const newKey = __.camelCase(key);
    newObj[newKey] = convertObjectKeysToCamelCase(obj[key]);
  });
  return newObj;
}

export const timeZone = {
  zone: 'Europe/Berlin',
  locale: enGB,
  format: 'yyyy-MM-dd HH:mm:ss zzz',
};
export function howLongAgo(
  dateString: string,
  prefix = 'ago',
  useDate?: null | Date
) {
  // const now = DateFn.(new Date(), timeZone.zone);
  const now = DateFn.toDate(new Date())
  // const date =DateFn.(new Date(dateString), timeZone.zone);
  const date =DateFn.toDate(new Date(dateString))
  if (new Date(now).getTime() < new Date(date).getTime()) {
    return 'still running';
  }
  const year = DateFn.differenceInYears(
    new Date(useDate || new Date()),
    new Date(date)
  );
  if (Number(year) > 0) {
    return `${year} years ${prefix}`;
  }

  const month = DateFn.differenceInMonths(
    new Date(useDate || new Date()),
    new Date(date)
  );
  if (Number(month) > 0) {
    return `${month} months ${prefix}`;
  }
  const week = DateFn.differenceInWeeks(
    new Date(useDate || new Date()),
    new Date(date)
  );
  if (Number(week) > 0) {
    return `${week} days ${prefix}`;
  }

  const day = DateFn.differenceInDays(
    new Date(useDate || new Date()),
    new Date(date)
  );
  if (Number(day) > 0) {
    return `${day} days ${prefix}`;
  }

  const hour = DateFn.differenceInHours(
    new Date(useDate || new Date()),
    new Date(date)
  );
  if (Number(hour) > 0) {
    return `${hour} hours ${prefix}`;
  }
  const minute = DateFn.differenceInMinutes(
    new Date(useDate || new Date()),
    new Date(date)
  );
  if (Number(minute) > 0) {
    return `${minute} minutes ${prefix}`;
  }
  const second = DateFn.differenceInSeconds(
    new Date(useDate || new Date()),
    new Date(date)
  );
  if (Number(second) > 0) {
    return `${second} seconds ${prefix}`;
  }
  return `a few seconds ${prefix}`;
}

export const customInputEventObject = (name: string, value: string): any => {
  return {
    target: {
      name, value
    }
  }
}


export const formatAPIErrorObject = (data: any) => {
  if (!Array.isArray(data?.errors)) return data;
  const newError: any = {};
  data?.errors.forEach((error: any) => {
    newError[error.field] = 'Field is required.'
  })
  return newError
}
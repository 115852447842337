import axios from 'axios';
var KirgawaSDK = /** @class */ (function () {
    function KirgawaSDK() {
        this.$http = axios;
        this.version = 'v1';
        this.__urls = {
            local: 'http://localhost:3301',
            dev: 'https://api.dev.kirgawa.com',
            prod: 'https://api.kirgawa.com'
        };
    }
    KirgawaSDK.prototype.init = function (_a) {
        var _b;
        var apiKey = _a.apiKey, _c = _a.context, context = _c === void 0 ? 'prod' : _c;
        this.__apiKey = apiKey;
        this.apiKey = this.__apiKey;
        var url = (_b = this.__urls[context]) !== null && _b !== void 0 ? _b : this.__urls.prod;
        this.$http = axios.create({
            baseURL: "".concat(url, "/sdk/").concat(this.version, "/"),
            headers: {
                'Content-Type': 'application/json',
                'X-Auth-Token': "SDK Bearer ".concat(this.__apiKey)
            }
        });
    };
    KirgawaSDK.prototype.getStageTimers = function () {
        var _a;
        return (_a = this.$http) === null || _a === void 0 ? void 0 : _a.get('/stage?limit=100');
    };
    KirgawaSDK.prototype.get = function (path) {
        var _a;
        return (_a = this.$http) === null || _a === void 0 ? void 0 : _a.get(path);
    };
    return KirgawaSDK;
}());
var kirgawa = new KirgawaSDK();
export default kirgawa;

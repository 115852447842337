import { init, useStageTimer } from 'kirgawa-react-sdk';
// import { init, useStageTimer } from './sdk';
import { useEffect, useState } from "react";

init({
  apiKey: "Y2xjeHZ3aGl4MDAxN2U3MXJkZTJxZHppYg.ckx5BRg2yJl8qJKdA2x12EQxWeqtf_eQyrSP0H6uG7Rouni47ws2hvO80dot",
  context: 'local'
})


export const ExampleApp = () => {
  const data = useStageTimer({  continuous: false, eventId: '7dfbeeec-ce2e-47ef-adca-e3dc758a4a07' }) as any;

  return <div>
    <button onClick={data.onEnd}>End</button>
    <button onClick={data.onStart}>Start</button>

    <div>Hour: {data.hour}</div>
    <div>Minute: {data.minute}</div>
    <div>Second: {data.second}</div>
    <div>Time spent: {data.timeSpent}</div>

  </div>;
};

// const POST = 'http://127.0.0.1:3301/v1/stage';
// const UIOptions = {
//   title: "What's the activity?",
//   startAt: '2023-01-15T16:02:17.132+01:00',
//   endAt: '2023-01-15T16:57:17.132+01:00',
//   shortcode: '',
//   meta: '{"duration":{"hour":0,"minute":55,"second":0},"description":"What\'s the activity?","backgroundColor":"rgb(0,100,0)"}',
// };
//
// const PATCH = 'http://127.0.0.1:3301/v1/stage/a8055f0e-cc00-4710-a624-6842e915e8d1';
// const UIOptionsPatch = {
//   startAt: '2023-01-15T15:59:47.000+01:00',
//   endAt: '2023-01-15T16:49:47.000Z',
//   shortcode: '3lqooHo',
//   title: 'Lecture with man',
//   meta: '{"duration":"00  :  18  :  29","backgroundColor":"rgb(128,0,0)","description":"Description(100)"}',
// };

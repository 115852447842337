const routes = {
  HOME: '/',
  LANDING: '/landing',
  FEATURES: '/features',
  PRICING: '/pricing',
  LOGIN: '/auth/login',
  RESET_PASSWORD: '/auth/reset-password',
  VERIFY_EMAIL: '/auth/verify-email',
  AUTH: '/auth',
  FORGOT_PASSWORD: '/auth/forgot-password',
  CREATE_PASSWORD: '/auth/create-password',
  REGISTER: '/auth/register',
  VIEW_TIMER: '/@:id',
  EDIT_TIMER: '@:id/edit',
  CREATE_TIMER: 'create-timer',
  COMING_SOON: '/coming-soon',
  ERROR_404: '*',
  ERROR_401: '/error-401',
  ERROR_500: '/error-500',
  ERROR_402: '/error-402',
  CONTACT: '/contact',
  ABOUT_US: '/about-us',
  PRIVACY: '/privacy',
  TERMS: '/terms',
  GOOGLE_AUTH: '/auth/google/callback',
  GOOGLE_AUTH2: '/google-callback',
  BOT_APP_LOGIN: '/auth/app'
};

export default routes;


import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import routes from '../routes';
import { getEnv } from './random';
import { useLocalStorage } from 'usehooks-ts';
import { LOCAL_STORAGE_BOT_APP_LOGIN } from '../shared/constant';

const useCurrentPage = () => {
  const { pathname } = useLocation();

  return {
    home: pathname === routes.HOME,
    features: pathname.includes(routes.FEATURES),
    pricing: pathname.includes(routes.PRICING),
  };
};

export default useCurrentPage;

export const ssoAuthUrl = (publicToken: string, url?: string) =>
  `${url ?? getEnv('REACT_APP_USER_URL')}/sso/auth?jwt=${publicToken}`;
export const useContinueToPage = (
  useSSO?: boolean
): [string | number, (v: string) => void] => {
  const [botAppLogin, setBotAPPLogin] = useLocalStorage(
    LOCAL_STORAGE_BOT_APP_LOGIN,
    '{}'
  );
  const [search] = useSearchParams();
  const rbt = search.get('rbt');
  const rbtStorage = sessionStorage.getItem('rbt');
  const ref = document.referrer
    ? document.referrer.includes('app.kirgawa') ||
      document.referrer.includes('127.0.0.1:3302')
      ? document.referrer
      : ''
    : '';
  const url = `${rbt ?? rbtStorage ?? ref ?? ''}`.replace(/\/$/, '');
  const navigate = useNavigate();

  const redirect = (goto?: string) => {
    sessionStorage.setItem('rbt', url ?? goto ?? '');
    if (goto && (!url || (url && url.toLowerCase().startsWith('http')))) {
      const page =
        url && url.toLowerCase().startsWith('http') ? url : undefined;
      window.location.href = useSSO ? ssoAuthUrl(goto, page) : goto;
    } else if (url) {
      // internal redirect url
      const botData = JSON.parse(botAppLogin || '{}');
      if (botData?.type && (botData?.id || botData?.token)) {
        const botUrl = `${url}?type=${botData.type}&id=${botData.id}&jwt=${goto}&token=${botData.token}`;
        navigate(botUrl);
        setBotAPPLogin('{}');
        return;
      }
      navigate(url);
    }
  };
  return [url, redirect as (v: string) => void];
};

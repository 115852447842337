import { __assign, __awaiter, __generator } from "tslib";
import kirgawa from '../KirgawaSDK';
import { useCallback, useEffect, useState } from 'react';
import { useApiKey } from './inits';
export var useStageTimer = function (options) {
    var key = useApiKey();
    if (!key) {
        throw new Error('API key must be set before using useStageTimer');
    }
    var _a = useState(0), hour = _a[0], setHour = _a[1];
    var _b = useState(0), minute = _b[0], setMinute = _b[1];
    var _c = useState(0), second = _c[0], setSecond = _c[1];
    var _d = useState(0), timestamp = _d[0], setTimestamp = _d[1];
    var _e = useState(0), timeSpent = _e[0], setTimeSpent = _e[1];
    var _f = useState(0), proposedEndAt = _f[0], setProposedEndAt = _f[1];
    var _g = useState(false), isStarted = _g[0], setIsStarted = _g[1];
    var _h = useState(false), isEnded = _h[0], setIsEnded = _h[1];
    var _j = useState(null), startAt = _j[0], setStartAt = _j[1];
    var _k = useState(null), error = _k[0], setError = _k[1];
    var _l = useState(null), stageInfo = _l[0], setStageInfo = _l[1];
    var onStart = function () { return __awaiter(void 0, void 0, void 0, function () {
        var startAt_1, endAt, data, startAtDate, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (isStarted) {
                        return [2 /*return*/];
                    }
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    startAt_1 = new Date().toISOString();
                    endAt = options.continuous ? undefined : stageInfo.endAt;
                    return [4 /*yield*/, kirgawa.$http.patch("/stage/".concat(options.eventId), __assign(__assign({}, stageInfo), { meta: JSON.stringify(__assign(__assign({}, JSON.parse(stageInfo.meta)), { duration: "".concat(hour, "  :  ").concat(minute, "  :  ").concat(second) })), startAt: startAt_1, endAt: endAt }))];
                case 2:
                    data = (_a.sent()).data;
                    startAtDate = new Date(startAt_1);
                    setStartAt(startAtDate);
                    setTimestamp(startAtDate.getTime());
                    setProposedEndAt(new Date(endAt).getTime());
                    setIsStarted(true);
                    setIsEnded(false);
                    setStageInfo(data);
                    return [3 /*break*/, 4];
                case 3:
                    e_1 = _a.sent();
                    setError(e_1);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var onEnd = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var endAt, meta, stamp, e_2;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (isEnded) {
                        return [2 /*return*/];
                    }
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, , 4]);
                    endAt = new Date().toISOString();
                    meta = JSON.parse(stageInfo.meta);
                    return [4 /*yield*/, kirgawa.$http.patch("/stage/".concat(options.eventId), __assign(__assign({}, stageInfo), { meta: JSON.stringify(__assign(__assign({}, meta), { laps: (_a = (meta.laps || [])) === null || _a === void 0 ? void 0 : _a.concat({ hour: hour, minute: minute, second: second, endAt: endAt, startAt: startAt === null || startAt === void 0 ? void 0 : startAt.toISOString() }), duration: "".concat(hour, "  :  ").concat(minute, "  :  ").concat(second) })), startAt: new Date(timestamp).toISOString(), endAt: endAt }))];
                case 2:
                    _b.sent();
                    setIsEnded(true);
                    stamp = (startAt === null || startAt === void 0 ? void 0 : startAt.getTime()) || timestamp;
                    setTimestamp(stamp);
                    setTimeSpent((new Date(endAt).getTime() - stamp) / 1000);
                    setIsStarted(false);
                    return [3 /*break*/, 4];
                case 3:
                    e_2 = _b.sent();
                    setError(e_2);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); }, [hour, minute, second, isEnded, options.eventId, stageInfo, startAt, timestamp]);
    var onReset = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, kirgawa.$http.get("/stage/".concat(options.eventId))];
                case 1:
                    data = (_a.sent()).data;
                    setStageInfo(data);
                    return [2 /*return*/];
            }
        });
    }); }, [options.eventId]);
    useEffect(function () {
        onReset();
    }, [onReset, options.eventId]);
    useEffect(function () {
        if (!isStarted) {
            return;
        }
        var checkTime = function () {
            setTimeSpent((Date.now() - timestamp) / 1000);
            setHour(Math.floor(timeSpent / 3600));
            setMinute(Math.floor(timeSpent / 60));
            setSecond(Math.floor((timeSpent % 60) + 0.848));
            if (!options.continuous && proposedEndAt && Date.now() >= proposedEndAt) {
                onEnd();
                clearInterval(intervalId);
            }
        };
        var intervalId = setInterval(checkTime, 1000);
        if (isEnded) {
            clearInterval(intervalId);
        }
        return function () { return clearInterval(intervalId); };
    }, [options.eventId, isStarted, options.continuous, timestamp, timeSpent, proposedEndAt, onEnd, isEnded]);
    return { hour: hour, minute: minute, second: second, timestamp: timestamp, onEnd: onEnd, onStart: onStart, timeSpent: timeSpent, error: error };
};
